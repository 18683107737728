<template>
  <div v-loading="loading" class="text-center">
    <h2 class="text-5xl text-primary">現在就加入JODY</h2>

    <El-Form ref="formRef" :model="form" :rules="rules" class="mt-6">
      <El-Form-item prop="email">
        <el-input :model-value="form.email" disabled></el-input>
      </El-Form-item>

      <Phone v-model="form.phone" :disabled="countdown > 0" check>
        <template #suffix="slot">
          <el-button
            size="small"
            round
            :disabled="!slot.valid || countdown > 0"
            type="primary"
            @click="requireCode({ phone: slot.phone })"
            >寄送驗證碼
            <span v-if="countdown > 0">剩下{{ countdown }}</span>
          </el-button>
        </template>
      </Phone>
      <El-Form-item
        prop="code"
        :rules="{
          required: true,
          message: '未填寫內容',
        }"
      >
        <div class>
          <El-Input v-model="form.code" placeholder="請輸入驗證碼"></El-Input>
        </div>
      </El-Form-item>

      <El-Form-item>
        <el-button
          :disabled="!form.code"
          type="primary"
          round
          class="w-full"
          @click="onSubmit"
          >手機驗證</el-button
        >
      </El-Form-item>
      <p class="my-2">
        註冊即是同意
        <router-link class="underline" :to="'/term'">使用者條款</router-link>
        <router-link class="underline ml-2" :to="'/privacy'"
          >隱私權條款</router-link
        >
      </p>
    </El-Form>
  </div>
</template>

<script>
import { ref, defineComponent, inject, reactive } from 'vue';
import { ElFormItem, ElForm, ElInput, ElMessage } from 'element-plus';
import Phone from '@/components/form/Phone.vue';

import { useRouter, useRoute } from 'vue-router';

import useChecker from '@/modules/auth/useChecker';
import User from '@/api/User';
import useGoogle from './useGoogle';

export default defineComponent({
  components: {
    ElFormItem,
    ElForm,
    ElInput,
    Phone,
  },
  setup() {
    const formRef = ref(null);
    const router = useRouter();
    const route = useRoute();
    const auth = inject('auth');
    const { fetcher: testerRecordFetcher } = inject('testerRecord');
    const { submit: requireCode, countdown } = useChecker(User.phoneCode);

    const { signin, verify } = useGoogle();
    const form = reactive({
      token: route.query.token,
      email: route.query.email,
      phone: '',
      code: '',
    });

    const onSubmit = () => {
      formRef.value.validate(async (valid) => {
        if (valid) {
          const v = await verify(form);
          if (v.code === 1) {
            const res = await signin({ token: form.token });
            if (res.code === 1) {
              auth.setAuth(res.data);
              testerRecordFetcher();
              router.push({ name: 'Signup.profile' });
            }
          } else {
            ElMessage.error('驗證碼錯誤');
          }
        } else {
          return false;
        }
      });
    };

    return {
      auth,
      onSubmit,
      form,
      formRef,
      requireCode,
      countdown,
    };
  },
});
</script>
